import React, { useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useMutation, gql } from '@apollo/client';
import { Box } from 'components/canvas/Box/Box';
import { Alert } from 'components/canvas/Alert/Alert';
import { Wrap } from 'components/composition/Wrap/Wrap';
import { Layout } from 'components/composition/Layout/Layout';
import { Grid } from 'components/composition/Grid/Grid';
import { FormGroup } from 'components/composition/FormGroup/FormGroup';
import { Spacing } from 'components/composition/Spacing/Spacing';
import { Title } from 'components/content/Title/Title';
import { Button } from 'components/controls/Button/Button';
import { Input } from 'components/controls/Input/Input';

const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password)
  }
`;

const Login = () => {
  const router = useRouter();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [login, { data }] = useMutation(LOGIN);

  const handleLogin = async e => {
    e.preventDefault();
    const res = await login({ variables: { username, password } });
    if (res.data.login) {
      router.push('/upload');
    }
  };

  return (
    <>
      <Head>
        <title>Login | The Food Waste Atlas</title>
      </Head>
      <Wrap small>
        <Layout>
          <Layout.Centered>
            <Box>
              <Title color="brandSecondary" spacing>
                Login
              </Title>
              {data && data.login === false && (
                <Spacing bottom="lg">
                  <Alert color="negative">
                    <strong>Oops!</strong>{' '}
                    The username or password entered was not recognised.
                  </Alert>
                </Spacing>
              )}
              <form onSubmit={handleLogin}>
                <FormGroup>
                  <Input
                    icon="user"
                    autoComplete="name"
                    required
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Username"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    icon="key"
                    autoComplete="current-password"
                    required
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </FormGroup>
                <Grid justify="flex-end">
                  <Grid.Item>
                    <Button type="submit">Log in</Button>
                  </Grid.Item>
                </Grid>
              </form>
            </Box>
          </Layout.Centered>
        </Layout>
      </Wrap>
    </>
  );
};

export default Login;
